// src/PastResults/PastResults.jsx
import React from "react";

export default function PastResults() {
    return (
        <div>
            <h2>Прошлые этапы</h2>
            <img src="/path/to/back_icon.png" alt="Назад" style={{ cursor: 'pointer' }} />
            {/* Здесь вы можете добавить список прошлых этапов */}
        </div>
    );
}